<!-- Autorización Capital Insuficiente -->

<template>
  <div class="autorizacion_CI">
   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{}">
        <v-btn                
          v-bind="$cfg.btn.busca_with_text"
          :disabled="disabled || rol!='jz'"
          @click="autorizacion_CI">
            AUTORIZACIÓN COS                   
        </v-btn>
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Controles del Mto -->
          <div class="ctrls conflex contenedor max-width:1240px" > 
            <div class="columna" style="width:750px">
              <div class="conflex" style="padding-bottom:10px">
                <v-btn                
                  v-bind="$cfg.btn.busca_with_text"
                  @click="send_autorizacion(1)">
                    <v-icon left>{{'mdi-check'}}</v-icon>
                    AUTORIZO                    
                </v-btn>

                <v-btn                
                  v-bind="$cfg.btn.busca_with_text"
                  style="margin-left:15px"
                  @click="send_autorizacion(0)">
                    <v-icon left>{{'mdi-close'}}</v-icon>
                    NO AUTORIZO                   
                </v-btn>
              </div>

              <ctrlobs3
                :schemaComponente="ctrls.chkautoriza_obs"
                :schema="schema"  
                :record="record"          
                :edicion="false"
              ></ctrlobs3>

              <v-textarea
                v-bind="$textarea"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                no-resize>
              </v-textarea>
            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
      
  export default {
    mixins: [mixinCtrls],
    components: { base_Header, ctrlobs3 },
    props: {
      padre: { type:String, default: '' }, 
      ctrls: { type:Object, default: null }, 
      record: { type:Object, default: null },  
      disabled: { type:Boolean, default: false },  
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            txt: { f:'txt', label:'Estas observaciones junto a los datos del Expediente serán remitidas al Centro Tramitador', value:""}
          }
        },
        api:'',
        stName:'stMautorizacion_CI',

        dialog:false,      
      };
    },


    created() {
      this.ini_data();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Autorización Capital Insuficiente COS"; 
      },


      //
      autorizacion_CI() {
        if (this.ctrls.chkcapital.value!='1') {
          this.$root.$alert.open('El CHECK de Capital Insuficiente debe estar seleccionado', 'error');
          return;
        }

        this.dialog= true;
      },


      //
      async send_autorizacion(aut) {
        var args = { tipo: "fnc", accion:'expediente', fn_args: { 
          accion:'autorizacionJZ', 
          exp_id: this.ctrls.id.value, 
          aut: aut,
          txt:this.schema.ctrls.txt.value }
        };     
       
        var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // paso los datos de expedientes a ctrls
        this.record2ctrl(apiResult.r[0][0], this.ctrls);

        // cierro ventana
        this.dialog= false;
      }
    }
  };
</script>
